import Header from "../../component/header";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function Plans() {
  const navigate = useNavigate();
  const [select_btn, set_select_btn] = useState<
    "classique" | "accelere" | "francais" | ""
  >("accelere");
  const [user_data, setuser_data] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  var pack_classique = [
    {
      id: 1.1,
      name: "PACK 13 HEURES",
      description: "Jusqu'à 3 h de conduite par semaine",
      check: "13 leçons de conduite",
      prix: 990,
    },
    {
      id: 1.2,
      name: "PACK 20 HEURES",
      description: "Jusqu'à 3 h de conduite par semaine",
      check: "20 leçons de conduite",
      prix: 1190,
    },
    {
      id: 1.3,
      name: "PACK 30 HEURES",
      description: "Jusqu'à 3 h de conduite par semaine",
      check: "30 leçons de conduite",
      prix: 1690,
    },
  ];
  var pack_accelere = [
    {
      id: 2.1,
      name: "PACK 13 HEURES",
      description: "Jusqu'à 3 h de conduite par jour",
      check: "13 leçons de conduite",
      prix: 1379,
    },
    {
      id: 2.2,
      name: "PACK 20 HEURES",
      description: "Jusqu'à 3 h de conduite par jour",
      check: "20 leçons de conduite",
      prix: 1580,
    },
    {
      id: 2.3,
      name: "PACK 30 HEURES",
      description: "Jusqu'à 3 h de conduite par jour",
      check: "30 leçons de conduite",
      prix: 2079,
    },
  ];

  var pack_francais = [
    {
      id: 3.1,
      name: "PACK 13 HEURES",
      description: "Jusqu'à 6 h de conduite par semaine",
      check: "13 leçons de conduite",
      prix: 1089,
    },
    {
      id: 3.2,
      name: "PACK 20 HEURES",
      description: "Jusqu'à 6 h de conduite par semaine",
      check: "20 leçons de conduite",
      prix: 1349,
    },
    {
      id: 3.3,
      name: "PACK 30 HEURES",
      description: "Jusqu'à 6 h de conduite par semaine",
      check: "30 leçons de conduite",
      prix: 1849,
    },
  ];
  useEffect(() => {
    document.title = "Choix du pack - CSR";
    if (localStorage.getItem("user_data")) {
      if (
        searchParams.get("nom") ||
        searchParams.get("prenom") ||
        searchParams.get("email") ||
        searchParams.get("tel") ||
        searchParams.get("codepostal") ||
        searchParams.get("offres") ||
        searchParams.get("situation") ||
        searchParams.get("datedenaissance")
      ) {
        searchParams.delete("nom");
        searchParams.delete("prenom");
        searchParams.delete("email");
        searchParams.delete("tel");
        searchParams.delete("codepostal");
        searchParams.delete("offres");
        searchParams.delete("situation");
        searchParams.delete("datedenaissance");
        setSearchParams(searchParams);
      }

      setuser_data(JSON.parse(localStorage.getItem("user_data") as string));
    } else if (!user_data) {
      const queryParameters = new URLSearchParams(window.location.search);

      const nom = queryParameters.get("nom");
      const prenom = queryParameters.get("prenom");
      const email = queryParameters.get("email");
      const tel = queryParameters.get("tel");
      const codepostal = queryParameters.get("codepostal");
      const offres = queryParameters.get("offres");
      const situation = queryParameters.get("situation");
      const datedenaissance = queryParameters.get("datedenaissance");

      if (
        nom &&
        prenom &&
        email &&
        tel &&
        codepostal &&
        offres &&
        situation &&
        datedenaissance
      ) {
        localStorage.clear();
        try {
          localStorage.setItem(
            "user_data",
            JSON.stringify({
              nom: nom,
              prenom: prenom,
              email: email,
              tel: tel,
              codepostal: codepostal,
              offres: offres,
              situation: situation,
              datedenaissance: datedenaissance,
            })
          );
          setuser_data(JSON.parse(localStorage.getItem("user_data") as string));

          navigate({
            pathname: "/plans",
          });
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      navigate({
        pathname: "/",
      });
    }
    console.log("tests push");
  }, []);

  function handle_button(id: number) {
    try {
      var selected_product;
      if (select_btn === "classique") {
        selected_product = "1." + id;
      } else if (select_btn === "accelere") {
        selected_product = "2." + id;
      } else if (select_btn === "francais") {
        selected_product = "3." + id;
      } else {
        console.log("no product selected");
      }
      console.log(user_data);
      if (selected_product && user_data) {
        const {
          nom,
          prenom,
          email,
          tel,
          codepostal,
          offres,
          situation,
          datedenaissance,
        } = user_data;

        if (
          nom &&
          prenom &&
          email &&
          tel &&
          codepostal &&
          offres &&
          situation &&
          datedenaissance
        ) {
          var tranfer_data = JSON.parse(
            localStorage.getItem("user_data") as string
          );
          tranfer_data.selected_product = selected_product;
          localStorage.setItem("user_data", JSON.stringify(tranfer_data));

          navigate({
            pathname: "/details",
          });

          // navigate({
          //   pathname: "/pay",
          //   search: `?nom=${nom}&prenom=${prenom}&email=${email}&tel=${tel}&codepostal=${codepostal}&offres=${offres}&selected_product=${selected_product}`,
          // });
        } else {
          console.log({
            nom,
            prenom,
            email,
            tel,
            codepostal,
            offres,
            situation,
          });
        }
      } else {
        const {
          nom,
          prenom,
          email,
          tel,
          codepostal,
          offres,
          situation,
          datedenaissance,
        } = user_data;
        console.log({
          nom,
          prenom,
          email,
          tel,
          codepostal,
          offres,
          situation,
          datedenaissance,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <Header />
      <div className="container mx-auto">
        <section className="bg-white">
          <div className="container px-6 py-8 mx-auto">
            <div className="max-w-2xl p-1.5 mx-auto overflow-hidden bg-gray-100 rounded-lg">
              <div className="grid gap-3 md:grid-cols-3">
                <button
                  className={
                    "px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform rounded-lg focus:outline-none hover:bg-gray-200 " +
                    (select_btn === "classique"
                      ? "bg-gray-200"
                      : "bg-transparent ")
                  }
                  onClick={() => set_select_btn("classique")}
                >
                  <span className="mx-1"> Classique</span>
                </button>

                <button
                  className={
                    "flex items-center justify-center px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform rounded-lg focus:outline-none hover:bg-gray-200 " +
                    (select_btn === "accelere"
                      ? "bg-gray-200"
                      : "bg-transparent ")
                  }
                  onClick={() => set_select_btn("accelere")}
                >
                  <span className="mx-1">Accéléré</span>
                </button>

                <button
                  className={
                    "flex items-center justify-center px-3 py-2 font-medium text-gray-800 uppercase transition-colors duration-300 transform rounded-lg focus:outline-none hover:bg-gray-200 " +
                    (select_btn === "francais"
                      ? "bg-gray-200"
                      : "bg-transparent ")
                  }
                  onClick={() => set_select_btn("francais")}
                >
                  <span className="mx-1">Français</span>
                  {/* <span className="text-xs mx-1 font-normal text-white bg-blue-500 rounded-full py-0.5 px-1.5">
                    save 20%
                  </span> */}
                </button>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-16 space-y-6 md:items-end md:-mx-5 md:space-y-0 md:flex-row">
              <div className="w-full px-6 py-4 transition-colors duration-300 transform rounded-lg md:mx-5 md:w-96 bg-gray-50">
                <div className="text-center">
                  <p className="text-2xl font-semibold text-gray-800">
                    PACK 13 HEURES
                  </p>
                  <p className="mt-4 text-gray-500">
                    {/* Jusqu'à 3 h de conduite par jour */}
                    {select_btn === "classique" &&
                      pack_classique[0].description}
                    {select_btn === "accelere" && pack_accelere[0].description}
                    {select_btn === "francais" && pack_francais[0].description}
                  </p>
                  <h4 className="mt-2 text-4xl font-semibold text-gray-800 ">
                    {/* 1379€ */}
                    {select_btn === "classique" &&
                      pack_classique[0].prix + " €"}
                    {select_btn === "accelere" && pack_accelere[0].prix + " €"}
                    {select_btn === "francais" && pack_francais[0].prix + " €"}
                  </h4>
                  {/* <p className="mt-4 text-gray-500">/per month</p> */}
                  <hr className="my-4 h-0.5 border-t-0 bg-red-100 opacity-100 dark:opacity-50" />
                </div>

                <div className="mt-8 space-y-8">
                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      13 leçons de conduite
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      Date d'examen garantie
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      Financement en CPF
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      Paiement en 3 x sans frais
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => {
                    handle_button(1);
                  }}
                  className="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  JE CHOISIS CE FORFAIT
                </button>
              </div>
              <div className="w-full px-6 py-4 transition-colors duration-300 transform rounded-lg md:mx-5 md:w-96 bg-gray-50">
                <div className="text-center">
                  <p className="text-2xl font-semibold text-gray-800 ">
                    PACK 20 HEURES
                  </p>
                  <p className="mt-4 text-gray-500">
                    {/* Jusqu'à 3 h de conduite par jour */}
                    {select_btn === "classique" &&
                      pack_classique[1].description}
                    {select_btn === "accelere" && pack_accelere[1].description}
                    {select_btn === "francais" && pack_francais[1].description}
                  </p>
                  <h4 className="mt-2 text-4xl font-semibold text-gray-800">
                    {/* 1580€ */}
                    {select_btn === "classique" &&
                      pack_classique[1].prix + " €"}
                    {select_btn === "accelere" && pack_accelere[1].prix + " €"}
                    {select_btn === "francais" && pack_francais[1].prix + " €"}
                  </h4>
                  {/* <p className="mt-4 text-gray-500">/per month</p> */}
                  <hr className="my-4 h-0.5 border-t-0 bg-red-100 opacity-100 dark:opacity-50" />
                </div>

                <div className="mt-8 space-y-8">
                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      20 leçons de conduite
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      Date d'examen garantie
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      Financement en CPF
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      Paiement en 3 x sans frais
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => {
                    handle_button(2);
                  }}
                  className="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  JE CHOISIS CE FORFAIT
                </button>
              </div>
              <div className="w-full px-6 py-4 transition-colors duration-300 transform rounded-lg md:mx-5 md:w-96 bg-gray-50">
                <div className="text-center">
                  <p className="text-2xl font-semibold text-gray-800">
                    PACK 30 HEURES
                  </p>
                  <p className="mt-4 text-gray-500">
                    {/* Jusqu'à 3 h de conduite par jour */}
                    {select_btn === "classique" &&
                      pack_classique[1].description}
                    {select_btn === "accelere" && pack_accelere[1].description}
                    {select_btn === "francais" && pack_francais[1].description}
                  </p>
                  <h4 className="mt-2 text-4xl font-semibold text-gray-800">
                    {/* 2079€
                     */}
                    {select_btn === "classique" &&
                      pack_classique[2].prix + " €"}
                    {select_btn === "accelere" && pack_accelere[2].prix + " €"}
                    {select_btn === "francais" && pack_francais[2].prix + " €"}
                  </h4>
                  {/* <p className="mt-4 text-gray-500">/per month</p> */}
                  <hr className="my-4 h-0.5 border-t-0 bg-red-100 opacity-100 dark:opacity-50" />
                </div>

                <div className="mt-8 space-y-8">
                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      30 leçons de conduite
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      Date d'examen garantie
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700 ">
                      Financement en CPF
                    </span>
                  </div>

                  <div className="flex items-center flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 text-blue-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span className="mx-4 text-gray-700">
                      Paiement en 3 x sans frais
                    </span>
                  </div>
                </div>

                <button
                  onClick={() => {
                    handle_button(3);
                  }}
                  className="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  JE CHOISIS CE FORFAIT
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Plans;
