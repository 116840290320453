import logo from "../assets/img/logo.webp";
import { Link } from "react-router-dom";

function header() {
  return (
    <nav className="flex light items-center bg-white p-1 flex-wra  border-b border-gray-200 mb-5">
      <Link to="/">
        <div href="#yolo" className="p-2 mr-4 inline-flex items-center">
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current text-gray-700 h-8 w-8 mr-2"
          ></svg>
          <span className="text-xl text-gray-700 font-bold uppercase tracking-wide">
            <img src={logo} alt="Logo" width={100} height={100} />
          </span>
        </div>
      </Link>
      <button
        className="text-gray-700 inline-flex p-3 hover:bg-gray-200 rounded lg:hidden ml-auto hover:text-gray-700 outline-none nav-toggler"
        data-target="#navigation"
        aria-controls="mobile-menu"
        aria-expanded="false"
      >
        <svg
          className="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
      <div
        className="top-navbar w-full lg:inline-flex lg:flex-grow lg:w-auto hidden"
        id="navigation"
      >
        <div className="lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto">
          <div className="lg:inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-700 items-center justify-center hover:bg-gray-200 hover:text-gray-700">
            <Link to="/contact">
              <span>Contact</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default header;
