import Header from "../../component/header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "../../firebase/Login";
import config from "../../config";
function Home() {
  const navigate = useNavigate();
  const [user_uuid, setuser_uuid] = useState<string | null>(null);
  const [Logintoken, setLogintoken] = useState<string | null>(null);
  const [nom, setnom] = useState<string>("");
  const [prenom, setprenom] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [codepostal, setcodepostal] = useState<string>("");
  const [datedenaissance, setdatedenaissance] = useState<string>("");
  const [tel, settel] = useState<string>("");
  const [situation, setsituation] = useState<string | undefined>("1");
  const [offres, setoffres] = useState<Boolean>(false);
  const [cgv, setcgv] = useState<Boolean>(false);
  const [can_validate, setcan_validate] = useState<Boolean | undefined>(false);
  const [isInit, setIsInit] = useState<boolean>(false);

  const toBool = (value: string | null): boolean => {
    return value === "true";
  };

  useEffect(() => {
    setIsInit(false);
    localStorage.getItem("form_cgv") &&
      setcgv(toBool(localStorage.getItem("form_cgv")));

    localStorage.getItem("form_situation") &&
      setsituation(localStorage.getItem("form_situation") as string);

    localStorage.getItem("form_nom") &&
      setnom(localStorage.getItem("form_nom") as string);

    localStorage.getItem("form_prenom") &&
      setprenom(localStorage.getItem("form_prenom") as string);

    localStorage.getItem("form_email") &&
      setemail(localStorage.getItem("form_email") as string);

    localStorage.getItem("form_codepostal") &&
      setcodepostal(localStorage.getItem("form_codepostal") as string);

    localStorage.getItem("form_datedenaissance") &&
      setdatedenaissance(
        localStorage.getItem("form_datedenaissance") as string
      );

    localStorage.getItem("form_tel") &&
      settel(localStorage.getItem("form_tel") as string);
    setIsInit(true);

    localStorage.getItem("form_offres") &&
      setoffres(toBool(localStorage.getItem("form_offres")));

    document.title = "Création de compte - CSR";
    if (!user_uuid && !Logintoken) {
      Login((uuid, token) => {
        if (uuid && token) {
          setuser_uuid(uuid);
          setLogintoken(token);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!isInit) return;
    // LoginFunction((uuid, token) => {
    //   if (uuid || token) {
    //     setuser_uuid(uuid);
    //     SetLogintoken(token);
    //   }
    // });
    localStorage.setItem("form_nom", nom);
    localStorage.setItem("form_prenom", prenom);
    localStorage.setItem("form_email", email);
    localStorage.setItem("form_codepostal", codepostal);
    localStorage.setItem("form_datedenaissance", datedenaissance);
    localStorage.setItem("form_tel", tel);
    localStorage.setItem("form_situation", situation as string);

    localStorage.setItem("form_offres", offres ? "true" : "false");
    localStorage.setItem("form_cgv", cgv ? "true" : "false");
  }, [
    nom,
    prenom,
    email,
    codepostal,
    datedenaissance,
    tel,
    situation,
    offres,
    cgv,
  ]);

  return (
    <>
      <Header />
      {/* start NGL CONTENT */}
      <div className="flex justify-center">
        <form className="w-full max-w-lg px-5 sm:px-0" onSubmit={handle_form}>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Nom
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Votre Nom"
                value={nom}
                onChange={(e) => setnom(e.target.value)}
                //allow only Alphabets and when input anthor things remove it and do not use on key press
                onKeyDown={(event) => {
                  if (!/[A-Za-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
              {/* <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Jane"
              />
              <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Prénom
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Votre Prénom"
                value={prenom}
                onChange={(e) => setprenom(e.target.value)}
                onKeyDown={(event) => {
                  if (!/[A-Za-z]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="email"
                placeholder="Votre Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Code Postal
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-city"
                type="number"
                placeholder="75000"
                value={codepostal}
                onChange={(e) => setcodepostal(e.target.value)}
                required
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Date de naissance
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-city"
                type="date"
                value={datedenaissance}
                onChange={(e) => setdatedenaissance(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Téléphone
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="number"
                placeholder="06 00 00 00 00"
                value={tel}
                onChange={(e) => settel(e.target.value)}
                onKeyDown={(event) => {
                  const allowedKeys = [
                    "Backspace",
                    "Delete",
                    "Tab",
                    "End",
                    "Home",
                    "ArrowLeft",
                    "ArrowRight",
                    " ",
                  ];
                  if (
                    allowedKeys.includes(event.key) ||
                    event.key.match(/\d/)
                  ) {
                    return;
                  }
                  event.preventDefault();
                }}
                required
              />
              {/* <p className="text-red-500 text">
                Votre numéro de téléphone doit contenir 10 chiffres
              </p>  */}
            </div>
          </div>
          {/*  */}
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Quelle est votre situation actuelle ?
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                  value={situation}
                  // onChange={(e) => setsituation(e.target.value)}
                  // onChange={(e) => {
                  //   setsituation(e.target.value);
                  // }}
                  onChange={(e) => {
                    setsituation(e.target.value);
                  }}
                  required
                >
                  <option value="1">Lycéen / étudiant</option>
                  <option value="2">Salarié</option>
                  <option value="3">Travailleur indépendant</option>
                  <option value="4">Fonctionnaire</option>
                  <option value="5">Sans emploi</option>
                  <option value="6">Autre catégorie</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <input
              id="default-checkbox"
              type="checkbox"
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => {
                setoffres(!offres);
              }}
              checked={!!offres}
            />
            <label className="ml-2 text-sm font-medium text-gray-900">
              J’accepte de recevoir des offres commerciales de la part de nos
              partenaires (La Poste…)
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="checked-checkbox"
              type="checkbox"
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              onChange={(e) => {
                setcgv(!cgv);
              }}
              // defaultChecked={Boolean(cgv)}
              checked={Boolean(cgv)}
              required
            />
            <label className="ml-2 text-sm font-medium text-gray-900">
              J'ai lu et j'accepte les CGV et la politique de confidentialité
              des données
            </label>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <button
                onFocus={() => {
                  setcan_validate(true);
                  Boolean(can_validate);
                }}
                // disabled={can_validate ? Boolean(can_validate) : true}
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center w-full mt-10 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                S'inscrire
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );

  function handle_form(e: any) {
    e.preventDefault();
    // send();
    send();
    navigate({
      pathname: "/plans",
      search: `?nom=${nom}&prenom=${prenom}&email=${email}&tel=${tel}&codepostal=${codepostal}&offres=${offres}&situation=${situation}&datedenaissance=${datedenaissance}`,
      // search: `?nom=${nom}&prenom=${prenom}&email=${email}&tel=${tel}&codepostal=${codepostal}&offres=${offres}&situation=${situation}`,
    });
  }

  async function send() {
    try {
      const url = config.url + "/api/utils/save_data";

      if (
        nom &&
        prenom &&
        email &&
        datedenaissance &&
        tel &&
        typeof offres === "boolean" &&
        codepostal &&
        situation
      ) {
        const body = {
          nom: nom,
          prenom: prenom,
          email: email,
          datedenaissance: datedenaissance,
          tel: tel,
          offres: offres,
          codepostal: codepostal,
          situation: situation,
        };
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const json = await response.json();

        if (response.status === 200) {
          return json;
        }
      } else {
        console.log("some value is nulled");
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default Home;
