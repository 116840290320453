// import React from "react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./assets/css/App.css";

import Home from "./pages/home";
import Plans from "./pages/home/plans";
import Contact from "./pages/contact";
import Pay from "./pages/pay";
import Details from "./pages/details";
import Confirmation from "./pages/confirmation";
import Klarnaconfirm from "./pages/Klarnaconfirm";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/details" element={<Details />} />
        <Route path="/confirmation/:id" element={<Confirmation />} />
        <Route path="/confirmation/?" element={<Klarnaconfirm />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<Home />} />
//       <Route path="/plans" element={<Plans />} />
//       <Route path="/contact" element={<Contact />} />
//       <Route path="/pay" element={<Pay />} />
//       <Route path="/details" element={<Details />} />
//     </Routes>
//   </BrowserRouter>
// );
