import Header from "../../component/header";
import { useEffect, useState } from "react";
import products from "../../data/product";
import { useNavigate } from "react-router-dom";
function Checkout() {
  const navigate = useNavigate();
  const [user_data, setuser_data] = useState<any | null>(null);
  const [product_details, setproduct_details] = useState<any>();
  const [total, settotal] = useState<number>();
  const [pack_plus, setpack_pack_plus] = useState<boolean>(false);

  useEffect(() => {
    setuser_data(JSON.parse(localStorage.getItem("user_data")!));
  }, []);
  useEffect(() => {
    if (user_data) {
      var product_details = products.find(
        (product) => product.id === user_data.selected_product
      );

      setproduct_details({
        name: product_details?.name,
        price: product_details?.prix,
        description: product_details?.description,
        type: product_details?.type.toUpperCase(),
        pack_plus: product_details?.pack_plus,
      });
      settotal(product_details?.prix);
      console.log(product_details);
    }
  }, [user_data]);

  useEffect(() => {
    if (pack_plus) {
      settotal(
        parseInt(product_details?.price) + parseInt(product_details?.pack_plus)
      );
    } else {
      settotal(product_details?.price);
    }
  }, [pack_plus]);
  return (
    <>
      <Header />

      <div className="h-screen bg-white 100 ">
        <h1 className="mb-10 text-center text-2xl font-bold">Récapitulatif</h1>
        <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
          <div className="rounded-lg md:w-2/3">
            <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/csrautoecole.appspot.com/o/photo-1515955656352-a1fa3ffcd111.jpg?alt=media&token=29c9f7f7-5d2d-45d2-a62b-7760f9c5482b"
                alt="pack de conduite csr"
                className="w-full rounded-lg sm:w-40"
              />
              <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                <div className="mt-5 sm:mt-0">
                  <h2 className="text-lg font-bold text-gray-900">
                    {product_details?.name} - {product_details?.type}
                  </h2>
                  <p className="mt-1 text-xs text-gray-700">
                    {product_details?.description}
                  </p>
                  <div className="flex flex-wrap -mx-3 m-3 mb-1">
                    <div className="w-full px-3">
                      <label className="relative inline-flex items-center mb-4 cursor-pointer">
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                          onChange={(e) => {
                            setpack_pack_plus(e.target.checked);
                          }}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900">
                          J'opte pour le pass + ({product_details?.pack_plus}€)
                        </span>
                      </label>
                      {/* icons */}
                      <div>
                        <h3>Avec le pass +</h3>
                      </div>
                      <div className="grid grid-cols-3 gap-1">
                        <div className="flex justify-center	 flex-row text-sm	">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            width={40}
                            height={40}
                            style={{ color: pack_plus ? "#2563EB" : "green" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                          <h3>Garantie 2ème chance</h3>
                        </div>
                        <div className="flex justify-center	 flex-row text-sm	">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            width={40}
                            height={40}
                            style={{ color: pack_plus ? "#2563EB" : "green" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                          <h3>Coaching Drive</h3>
                        </div>
                        <div className="flex justify-center	 flex-row text-sm	">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            width={40}
                            height={40}
                            style={{ color: pack_plus ? "#2563EB" : "green" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                          <h3>Service à domicile</h3>
                        </div>
                      </div>
                      {/* icons */}
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                  <div className="flex items-center space-x-4">
                    <p className="text-sm">{product_details?.price}€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Sous total</p>
              <p className="text-gray-700">{total} €</p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold">Total TTC</p>
              <div className="">
                <p className="mb-1 text-lg font-bold">{total} €</p>
                <p className="text-sm text-gray-700">Tva inclus</p>
              </div>
            </div>
            <button
              onClick={() => single_payment()}
              className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600"
            >
              Payer
            </button>
            <div className="my-5	flex flex-col	 justify-between items-center">
              <p className="text-lg font-bold">ou</p>
            </div>
            <button
              onClick={() => multi_payment()}
              className="w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600"
            >
              Payer en plusieurs fois
            </button>
          </div>
        </div>
      </div>
    </>
  );

  function single_payment() {
    if (localStorage.getItem("user_data")) {
      if (localStorage.getItem("order")) {
        localStorage.removeItem("order");
      }
      var tranfer_data = JSON.parse(localStorage.getItem("user_data")!);
      pack_plus
        ? (tranfer_data.pack_plus = true)
        : (tranfer_data.pack_plus = false);
      localStorage.setItem("user_data", JSON.stringify(tranfer_data));
      localStorage.setItem("payment_type", "single");
      navigate({
        pathname: "/pay",
      });
    }
  }
  function multi_payment() {
    if (localStorage.getItem("user_data")) {
      if (localStorage.getItem("order")) {
        localStorage.removeItem("order");
      }
      var tranfer_data = JSON.parse(localStorage.getItem("user_data")!);
      pack_plus
        ? (tranfer_data.pack_plus = true)
        : (tranfer_data.pack_plus = false);
      localStorage.setItem("user_data", JSON.stringify(tranfer_data));
      localStorage.setItem("payment_type", "multi");
      navigate({
        pathname: "/pay",
      });
    }
  }
}

export default Checkout;
