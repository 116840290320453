import { useEffect, useState } from "react";
import Login from "../../firebase/Login";
import config from "../../config";
// import {  useStripe,  useElements,   PaymentElement,   Elements,} from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Header from "../../component/header";
import PaymentCheckout from "./checkout";
import { Stripe, loadStripe } from "@stripe/stripe-js";

const stripe_pk = process.env.REACT_APP_STRIPE_PRIVE_KEY!;
interface TUserData {
  nom: string;
  prenom: string;
  email: string;
  offres: string;
  pack_plus: boolean;
  selected_product: string;
  tel: string;
  codepostal: string;
  situation: string;
  datedenaissance: string;
  payment_type: string;
}
const Pay = () => {
  const [user_data, setuser_data] = useState<TUserData | null>(null);
  const [user_uuid, setuser_uuid] = useState<string | null>();
  const [Logintoken, setLogintoken] = useState<string | null>(null);
  const [error_message, seterror_message] = useState<string | null>(null);
  const [total_amount, settotal_amount] = useState<number>(0);

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  const [clientSecret, setclientSecret] = useState();
  const [message, setMessage] = useState<string | null>(null);
  const [messageTimer, setMessageTimer] = useState<any>(null);
  const [messageErrorTimer, setMessageErrorTimer] = useState<any>(null);

  useEffect(() => {
    if (localStorage.getItem("user_data")) {
      setuser_data(() => {
        return JSON.parse(localStorage.getItem("user_data")!);
      });
    }
  }, []);
  useEffect(() => {
    console.log(user_uuid);
  }, [user_uuid]);

  useEffect(() => {
    Login((uuid, token) => {
      if (uuid && token) {
        setuser_uuid(uuid);
        setLogintoken(token);
      }
    });
    setStripePromise(loadStripe(stripe_pk, { apiVersion: "2020-03-02" }));
  }, []);

  useEffect(() => {
    if (user_uuid && Logintoken && user_data) {
      loadData(user_uuid, Logintoken);
    } else {
    }
  }, [user_data, user_uuid, Logintoken]);

  useEffect(() => {
    if (messageErrorTimer) {
      clearTimeout(messageErrorTimer);
    }
    setMessageErrorTimer(
      setTimeout(() => {
        seterror_message(null);
      }, 7000)
    );
  }, [error_message]);
  useEffect(() => {
    if (messageTimer) {
      clearTimeout(messageTimer);
    }
    setMessageTimer(
      setTimeout(() => {
        setMessage(null);
      }, 7000)
    );
  }, [message]);

  const loadData = async (user_uuid: string, token: string) => {
    if (user_uuid && token) {
      if (!localStorage.getItem("order")) {
        try {
          const url = config.url + "/api/payment/create_pi";
          const nom = user_data?.nom;
          const prenom = user_data?.prenom;
          const email = user_data?.email;
          const tel = user_data?.tel;
          const codepostal = user_data?.codepostal;
          const offres = user_data?.offres;
          const selected_product = user_data?.selected_product;
          const pack_plus = user_data?.pack_plus;
          const situation = user_data?.situation;
          const datedenaissance = user_data?.datedenaissance;
          const payment_method = localStorage.getItem("payment_type");

          if (
            nom &&
            prenom &&
            email &&
            tel &&
            codepostal &&
            offres &&
            selected_product &&
            pack_plus !== null &&
            situation &&
            datedenaissance
          ) {
            const body = {
              uuid: user_uuid,
              token: token,
              nom: nom,
              prenom: prenom,
              email: email,
              tel: tel,
              codepostal: codepostal,
              offres: offres,
              product_id: selected_product,
              pack_plus: pack_plus,
              situation: situation,
              datedenaissance: datedenaissance,
              payment_method: payment_method,
            };
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            });
            const json = await response.json();

            if (response.status === 200) {
              localStorage.setItem("order", JSON.stringify(json));
              setclientSecret(json.client_secret);
              settotal_amount(json.amount);
            } else if (response.status === 400) {
              if (user_uuid && token) {
                loadData(user_uuid, token);
              }
            } else {
              setMessage(json.message);
            }
          } else {
            console.log("some value is nulled");
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        const json = JSON.parse(localStorage.getItem("order")!);
        settotal_amount(json.amount);
        setclientSecret(json.client_secret);
      }
    } else if (user_uuid && Logintoken) {
      loadData(user_uuid, Logintoken);
    }
  };

  return (
    <div>
      <Header />
      {error_message && (
        <>
          <div className=" flex justify-center items-center">
            <div
              className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-800 dark:text-red-400 w-1/2 justify-center"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Error</span>
              <div>{error_message}</div>
            </div>
          </div>
        </>
      )}
      {message && (
        <div className=" flex justify-center items-center">
          <div
            className="flex p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 w-1/2 justify-center"
            role="alert"
          >
            <svg
              aria-hidden="true"
              className="flex-shrink-0 inline w-5 h-5 mr-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Info</span>
            <div>{message}</div>
          </div>
        </div>
      )}

      {stripePromise && clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance: { theme: "flat" } }}
        >
          <PaymentCheckout
            total_amount={total_amount}
            setmessage={(message, type) => {
              if (type === "error") {
                seterror_message(message);
              } else {
                setMessage(message);
              }
            }}
          />
        </Elements>
      )}
    </div>
  );
};

export default Pay;
