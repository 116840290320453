import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import Loader from "../../component/loader";
import { useNavigate } from "react-router-dom";

const PaymentCheckout = (props: {
  setmessage: (message: string, type: "message" | "error") => void;
  total_amount: number;
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const elements = useElements();
  const stripe = useStripe();
  const { setmessage, total_amount } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [user_data, setuser_data] = useState<any>({});
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isLoading) return;
    if (!stripe || !elements) return;
    setIsProcessing(true);
    console.log("try to pay");

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/confirmation`,
      },
      redirect: "if_required",
    });

    if (error) {
      setmessage(error.message!, "error");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      if (paymentIntent.status === "succeeded") {
        console.log(paymentIntent.status);
        setTimeout(() => {
          const json = JSON.parse(localStorage.getItem("order")!);
          navigate(`/confirmation/${json.order_id}`);

          setIsLoading(false);
        }, 500);
      } else if (
        paymentIntent.status === "requires_action" ||
        paymentIntent.status === "requires_payment_method" ||
        paymentIntent.status === "requires_confirmation" ||
        paymentIntent.status === "processing" ||
        paymentIntent.status === "requires_capture" ||
        paymentIntent.status === "canceled"
      ) {
        navigate(`/error/`);
      } else {
        console.log(paymentIntent.status);
      }
    }
    setIsProcessing(false);
  };
  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(() => {
    console.log(isReady);
    console.log(user_data);
    if (!user_data) {
      setuser_data(JSON.parse(localStorage.getItem("user_data")!));
    }
  }, [isReady, user_data]);

  return (
    <form
      onSubmit={handleSubmit}
      className=" items-center flex flex-col h-screen w-full"
    >
      {!isReady && <Loader />}
      <div
        className={
          (isReady ? "visible" : "hidden") +
          " w-full  items-center flex flex-col"
        }
      >
        <div className="w-1/2">
          <AddressElement options={{ mode: "billing" }} />
          <PaymentElement
            className="my-9"
            onReady={() => {
              setIsReady(true);
              setIsLoading(false);
            }}
          />
        </div>

        <div className="w-full flex justify-center items-center py-2">
          <div className="w-1/2 flex justify-end">
            <h1 className="font-bold">Montant Total : {total_amount}€</h1>
          </div>
        </div>
        <div>
          <div className="w-1/2 flex items-center">
            <button
              type="submit"
              disabled={isProcessing}
              className="w-56 flex justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <span className="bg-primary-600 text-white w-72 h-10 rounded-md flex justify-center items-center">
                {isProcessing ? "Processing ..." : "Pay now"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentCheckout;
