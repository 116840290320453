import { useEffect, useState } from "react";
import Header from "../../component/header";
import { useParams } from "react-router-dom";
import Loader from "../../component/loader";
// import config from "../../config";
function Confirmation() {
  const params = useParams();
  const [success, setsuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  console.log({ params });

  useEffect(() => {
    document.title = "Confirmation - CSR";
    setIsLoading(false);
    setsuccess(true);
    localStorage.clear();
  }, []);

  // const load = async () => {
  //   const url = config.url + "/api/payment/create_pi";
  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ order_id: params.id }),
  //   });
  //   const json = await response.json();

  //   if (response.status === 200) {
  //     localStorage.setItem("order", JSON.stringify(json));
  //   } else if (response.status === 400) {
  //   }
  // };

  return (
    <>
      <Header />
      <div className="w-full">
        {isLoading ? (
          <Loader />
        ) : (
          // <>{success ? <Success id={params.id!} /> : <h1>Erreur</h1>}</>
          <>{success ? <Success /> : <h1>Erreur</h1>}</>
        )}
      </div>
    </>
  );
}

// const Success = (propos: { id: string }) => {
// const { id } = propos;
const Success = () => {
  return (
    <div className="h-full w-full flex justify-center items-center bg-white">
      <div className="bg-white p-6 h-full">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-44 h-44 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            {/* Nᵒ de commande :<br /> {id} */}
            INSCRIPTION VALIDÉE
          </h3>
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Bienvenue vous êtes inscrit à l'auto ecole
            <br />
            CSR Boulogne Billancourt
          </h3>
          {/* <p className="text-gray-600 my-2">Merci pour votre commande</p> */}
          <p className="my-5">
            Notre Equipe prendra desormais contact avec vous dans moins de 24h{" "}
            <br />
            pour organiser votre planning selon vos disponiblité. <br />
            Votre réussite est notre priorité et nous sommes impatients de
            <br />
            vous accompagner dans cette étape importante de votre apprentissage
            de la conduite.
          </p>
          <button
            type="button"
            className="mx-5	 my-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <a href="https://csr-autoecole-boulogne-billancourt.com/">
              Retour à l'accueil
            </a>
          </button>
          <button
            type="button"
            className="mx-5 my-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <a href="tel:0140961199">Nous contacter</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
