const products = [
  {
    id: "1.1",
    name: "PACK 13 HEURES",
    description: "Jusqu'à 3 h de conduite par semaine",
    check: "13 leçons de conduite",
    prix: 990,
    type: "classique",
    pack_plus: 390,
  },
  {
    id: "1.2",
    name: "PACK 20 HEURES",
    description: "Jusqu'à 3 h de conduite par semaine",
    check: "20 leçons de conduite",
    prix: 1190,
    type: "classique",
    pack_plus: 390,
  },
  {
    id: "1.3",
    name: "PACK 30 HEURES",
    description: "Jusqu'à 3 h de conduite par semaine",
    check: "30 leçons de conduite",
    prix: 1690,
    type: "classique",
    pack_plus: 390,
  },
  {
    id: "2.1",
    name: "PACK 13 HEURES",
    description: "Jusqu'à 3 h de conduite par jour",
    check: "13 leçons de conduite",
    prix: 1379,
    type: "accelere",
    pack_plus: 390,
  },
  {
    id: "2.2",
    name: "PACK 20 HEURES",
    description: "Jusqu'à 3 h de conduite par jour",
    check: "20 leçons de conduite",
    prix: 1580,
    type: "accelere",
    pack_plus: 390,
  },
  {
    id: "2.3",
    name: "PACK 30 HEURES",
    description: "Jusqu'à 3 h de conduite par jour",
    check: "30 leçons de conduite",
    prix: 2079,
    type: "accelere",
    pack_plus: 390,
  },
  {
    id: "3.1",
    name: "PACK 13 HEURES",
    description: "Jusqu'à 6 h de conduite par semaine",
    check: "13 leçons de conduite",
    prix: 1089,
    type: "francais",
    pack_plus: 390,
  },
  {
    id: "3.2",
    name: "PACK 20 HEURES",
    description: "Jusqu'à 6 h de conduite par semaine",
    check: "20 leçons de conduite",
    prix: 1349,
    type: "francais",
    pack_plus: 390,
  },
  {
    id: "3.3",
    name: "PACK 30 HEURES",
    description: "Jusqu'à 6 h de conduite par semaine",
    check: "30 leçons de conduite",
    prix: 1849,
    type: "francais",
    pack_plus: 390,
  },
];
export default products;
